






























import { Component, Vue, Prop } from 'vue-property-decorator'
import { pxToVw } from '@/common/utils'
@Component
export default class extends Vue {
  @Prop({
    type: Number,
    default: 0
  })
  percentage!: number
  @Prop({
    type: Number,
    default: 2
  })
  strokeWidth!: number //进度条宽度
  @Prop({
    type: Number,
    default: 20
  })
  width!: number
  @Prop({
    type: String,
    default: '#E93125'
  })
  circleColor!: string
  pxToVw = pxToVw
  get relativeStrokeWidth() {
    return ((this.strokeWidth / this.width) * 100).toFixed(1)
  }
  get radius() {
    return parseInt(`${50 - parseFloat(this.relativeStrokeWidth) / 2}`, 10)
  }
  get trackPath() {
    const r = this.radius
    const isDashboard = false
    return `
          M 50 50
          m 0 ${isDashboard ? '' : '-'}${r}
          a ${r} ${r} 0 1 1 0 ${isDashboard ? '-' : ''}${r * 2}
          a ${r} ${r} 0 1 1 0 ${isDashboard ? '' : '-'}${r * 2}
          `
  }
  get perimeter() {
    return 2 * Math.PI * this.radius
  }
  get strokeDashoffset() {
    const offset = (-1 * this.perimeter * (1 - 1)) / 2
    return `${offset}px`
  }
  get trailPathStyle() {
    return {
      strokeDasharray: `${this.perimeter}px, ${this.perimeter}px`,
      strokeDashoffset: this.strokeDashoffset
    }
  }
  get circlePathStyle() {
    return {
      strokeDasharray: `${this.perimeter * 1 * (this.percentage / 100)}px, ${
        this.perimeter
      }px`,
      strokeDashoffset: this.strokeDashoffset,
      transition: 'stroke-dasharray 0.6s ease 0s, stroke 0.6s ease'
    }
  }
}
