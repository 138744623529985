






































import { Toast } from 'vant'
import { Component, Vue } from 'vue-property-decorator'
import { isIOS } from '@/common/utils/device/getSystemInfo'
import { CommonModule } from '@/store/modules/common'
import { submitFeedback } from '../../../api'

import IOSUpload from './components/IOSUpload.vue'
import ProgressCircle from '@/pages/user/components/progressCircle.vue'
import {
  callAppFunc_setNavColor,
  callAppFunc_setTitleFontColor
} from '@/common/utils/bridge'
@Component({
  name: 'HelpCommit',
  components: {
    IOSUpload,
    ProgressCircle
  }
})
export default class extends Vue {
  textarea = ''
  isIOS = isIOS
  fileList: Array<any> = []
  repeatClick = true
  onChange(list: Array<UploadFileList>) {
    this.fileList = list
  }
  async submit() {
    if (this.repeatClick) {
      this.repeatClick = false
      const deviceModel = CommonModule.deviceModel
      const images = this.fileList.map(item => item.url)
      if (!this.textarea.length) {
        this.repeatClick = true
        return Toast(this.$t('请填写反馈内容'))
      }

      if (this.fileList.some((item: any) => item.status === 'uploading')) {
        this.repeatClick = true
        return Toast(this.$t('图片上传中，请稍等...'))
      }
      await submitFeedback({
        model: deviceModel,
        images: JSON.stringify(images),
        content: this.textarea
      })
      this.textarea = ''
      this.fileList = []
      this.repeatClick = true
      Toast(this.$t('提交成功，我们将尽快处理您的反馈'))
    }
  }

  mounted() {
    callAppFunc_setNavColor({ navColor: '#FFFFFF' })
    callAppFunc_setTitleFontColor({ navFontColor: '#000000' })
  }
}
